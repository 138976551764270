<template>

</template>
<script>
export default {
  name: "notFound",
  created: function() {
        // Redirect outside the app using plain old javascript
        window.location.href = "https://app.canpaydebit.com/splash";
    }
};
</script>
